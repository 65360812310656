import { Verk } from "@forrigebok/generatedtypes";
import { localStorageStringArray } from "./localStorageStringArray";
import { logClick } from "./Plausible";

const localStorageKey = "analyticsVerkSomTrengerFlereRegistreringer";

const { getValues, addValue, removeValue } = localStorageStringArray(localStorageKey);

export const analyticsVerkSomTrengerFlereRegistreringer = {
  forsideKlikk: (verk: Pick<Verk, "_id">) => {
    logClick("Bidra: Klikk forside");
    addValue(verk._id);
  },
  lagRegistrering: (verk?: Pick<Verk, "_id">) => {
    if (verk?._id && getValues().includes(verk._id)) {
      logClick("Bidra: Start registrering");
    }
  },
  publiserRegistrering: (verk?: Pick<Verk, "_id">) => {
    if (verk && getValues().includes(verk._id)) {
      logClick("Bidra: Publiser registrering");
      removeValue(verk._id);
    }
  },
};
