import { Stack } from "@chakra-ui/react";
import { useTranslation } from "src/utils/useTranslation";
import { InnloggetForsideRespons } from "../../pages/api/internal/forside/innlogget.api";
import { analyticsSisteRegistreringer } from "../../utils/analytics/analyticsSisteRegistreringer";
import { SWRStatus } from "../SWRStatus";
import { useForsideData } from "./useForsideData";
import { VerkListe } from "./VerkListe";

export function SisteRegistreringer() {
  const forsideRespons = useForsideData();
  const { t } = useTranslation();

  if (!forsideRespons.data) return <SWRStatus centerSpinner swrResponse={forsideRespons} />;

  return (
    <Stack spacing="1rem" marginTop="1rem">
      <Liste tittel={t("Siste voksenbøker")} registreringer={forsideRespons.data.sisteRegistreringer.voksen} />
      <Liste tittel={t("Siste barnebøker")} registreringer={forsideRespons.data.sisteRegistreringer.barn} />
      <Liste
        tittel={t("Siste illustrerte bøker")}
        registreringer={forsideRespons.data.sisteRegistreringer.illustrert}
      />
    </Stack>
  );
}

const Liste = (props: { tittel: string; registreringer: InnloggetForsideRespons["sisteRegistreringer"]["voksen"] }) => (
  <VerkListe
    tittel={props.tittel}
    verk={props.registreringer.map((registrering) => registrering.verk)}
    onClick={(verk) => analyticsSisteRegistreringer.forsideKlikk(verk)}
  />
);
