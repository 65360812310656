import { Box, BoxProps, Container, Heading, HStack, StackProps, Text, VStack } from "@biblioteksentralen/react";
import { CountDownConfig } from "@forrigebok/generatedtypes";
import { jsonFetcher } from "@forrigebok/common";
import { motion } from "framer-motion";
import { normalFarge } from "../utils/colors";
import { useSanityStats } from "./forside/useSanityStats";
import useSWR from "swr";
import { SWRStatus } from "./SWRStatus";
import { useTranslation } from "src/utils/useTranslation";
import { formatNumberWithPunctuation } from "src/utils/formatNumberWithPunctuation";

function CountDown() {
  const stats = useSanityStats();
  const countDownConfigResponse = useSWR<CountDownConfig>("/api/internal/count-down-config", jsonFetcher);
  const { t } = useTranslation();

  if (countDownConfigResponse.error) return <SWRStatus swrResponse={countDownConfigResponse} />;
  if (!countDownConfigResponse.data) return null;

  const { startAt, goal, hideAfter } = countDownConfigResponse.data;

  if (!startAt || !goal || !hideAfter) return null;

  const count = stats.data?.antallRegistreringer ?? 0;
  const antallRegistreringerIgjen = goal - count;

  if (count < startAt || count > hideAfter) return null;

  if (count >= goal)
    return (
      <Style spacing={".2em"}>
        <Heading fontSize={{ base: "3xl", md: "5xl" }}>
          {t("{count} registreringer!", { count: formatNumberWithPunctuation(goal) })}
        </Heading>
        <Text fontSize={{ base: "3em", sm: "5em", md: "7em" }}>📚 🥳 📚</Text>
      </Style>
    );

  return (
    <Style spacing=".75em">
      <Heading fontSize="1em">
        {t("Vi når snart {count} registreringer!", { count: formatNumberWithPunctuation(goal) })}
      </Heading>
      <Teller count={antallRegistreringerIgjen} />
      <Box fontWeight="600">{t("registreringer igjen", { count: antallRegistreringerIgjen })}</Box>
    </Style>
  );
}

const Style = (props: StackProps) => (
  <Container maxW="container.lg">
    <VStack background={normalFarge} color="white" padding="2rem" borderRadius="lg" fontSize="1.25em" {...props} />
  </Container>
);

export const Teller = ({
  count,
  skiveFarge,
  ...rest
}: { count: number; skiveFarge?: BoxProps["background"] } & BoxProps) => (
  <HStack spacing=".3em" color={"gray.800"} fontSize="1.5em" {...rest}>
    {Array.from(count.toFixed(0)).map((number, i) => (
      <motion.div
        key={i + number}
        initial={{ transform: "rotate3d(1,0,0,90deg)" }}
        animate={{ transform: "rotate3d(1,0,0,0deg)" }}
        transition={{ type: "spring", stiffness: 300, damping: 10, delay: i * 0.1 }}
        style={{ transformOrigin: "top" }}
      >
        <Box
          background={skiveFarge ?? "gray.100"}
          borderRadius="md"
          height="2em"
          width="1.2em"
          display="grid"
          placeItems="center"
          boxShadow="xl"
        >
          {number}
        </Box>
      </motion.div>
    ))}
  </HStack>
);

export default CountDown;
