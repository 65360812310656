import { Box, colors, Container, SimpleGrid, Stack, StackProps } from "@biblioteksentralen/react";
import { FunctionComponent, ReactNode } from "react";
import { useSanityStats } from "./useSanityStats";
import NextLink from "next/link";
import { SWRStatus } from "../SWRStatus";
import React from "react";
import { useTranslation } from "src/utils/useTranslation";
import { formatNumberWithPunctuation } from "src/utils/formatNumberWithPunctuation";

function Stats() {
  const stats = useSanityStats();
  const { t } = useTranslation();

  return (
    <Container maxW="container.lg">
      <SimpleGrid columns={2} gap=".75rem">
        <Stat
          top="Vi har"
          middle={
            !stats.data ? (
              <SWRStatus marginY="1rem" swrResponse={stats} />
            ) : (
              <Box fontSize={{ base: "4xl", sm: "5xl" }}>
                {formatNumberWithPunctuation(stats.data?.antallRegistreringer)}
              </Box>
            )
          }
          bottom={t("registreringer totalt")}
          ariaLabel={t("Antall registreringer totalt")}
        />
        <NextLink href="/mine-registreringer">
          <Stat
            top={t("Du har bidratt med")}
            _hover={{ backgroundColor: colors.neptune[200] }}
            transition=".2s"
            middle={
              !stats.data ? (
                <SWRStatus marginY="1rem" swrResponse={stats} />
              ) : (
                <Box fontSize={{ base: "4xl", sm: "5xl" }}>
                  {formatNumberWithPunctuation(stats.data?.brukersRegistreringer.publiserteRegistreringerCount)}
                </Box>
              )
            }
            bottom={t("registreringer")}
            ariaLabel={t("Antall registreringer av deg")}
          />
        </NextLink>
      </SimpleGrid>
    </Container>
  );
}

type StatProps = { top: ReactNode; middle: ReactNode; bottom: ReactNode; ariaLabel: string } & StackProps;

// eslint-disable-next-line react/display-name
const Stat: FunctionComponent<StatProps> = React.forwardRef<HTMLDivElement, StatProps>(
  ({ top, middle, bottom, ariaLabel, ...chakraProps }, ref) => (
    <Stack
      fontWeight={600}
      backgroundColor={colors.neptune[100]}
      padding={{ base: "1rem", sm: "1.25rem" }}
      borderRadius="md"
      spacing={0}
      as="section"
      ref={ref}
      aria-label={ariaLabel}
      {...chakraProps}
    >
      <Box fontSize={{ base: "sm", sm: "md" }}>{top}</Box>
      <Box>{middle}</Box>
      <Box fontSize={{ base: "sm", sm: "md" }}>{bottom}</Box>
    </Stack>
  )
);

export default Stats;
