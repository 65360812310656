import { List } from "@biblioteksentralen/react";
import { Heading, Stack } from "@chakra-ui/react";
import { Verk } from "@forrigebok/generatedtypes";
import { VerkMedStats } from "../../pages/api/internal/verk/index.api";
import VerkPreview from "../work/VerkPreview";
import { useId } from "react";

interface Props {
  tittel: string;
  verk: VerkMedStats[];
  onClick?: (verk: Pick<Verk, "_id">) => void;
}

export const VerkListe = (props: Props) => {
  const id = useId();

  return (
    <Stack spacing="1rem">
      <Heading id={id} size="lg">
        {props.tittel}
      </Heading>
      <List
        aria-labelledby={id}
        display="grid"
        gridGap="calc(1rem + 1vmin)"
        gridTemplateColumns="repeat(auto-fit, minmax(8rem,1fr))"
        justifyItems="center"
      >
        {props.verk.map((verk) => (
          <VerkPreview
            maxW="11rem"
            width="100%" // Bilder med lav oppløsning trenger width 100% for å bli strukket ut og fylle hele bredden
            key={verk._id}
            verk={verk}
            onClick={() => props.onClick?.(verk)}
          />
        ))}
      </List>
    </Stack>
  );
};
