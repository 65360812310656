import { getBibbiWork } from "@forrigebok/common";
import { BibbiData } from "@forrigebok/generatedtypes";
import { mutate } from "swr";
import useSWRImmutable from "swr/immutable";

const getSwrKey = (workId?: string, isbn?: string) => workId ?? isbn ?? null;

export function useBibbiWork(workId?: string, isbn?: string) {
  return useSWRImmutable(getSwrKey(workId, isbn), async () => getBibbiWork({ isbn, workId }));
}

// Prepopulerer cache for raskere respons i frontend. Denne dataen kan allerede ha kommet via bibbiSearch hvis brukeren har søkt etter bøker, eller den kan komme fra Sanity-cachen hvis man navigerer i ting som allerede er registert og dermed cachet i Sanity
// Dette gjør også at store deler av appen vil funke hvis bibbi er nede fordi vi prepopulerer med cachet data fra Sanity der det er mulig
export const setBibbiRecordSWRCache = (bibbiData?: BibbiData) => {
  if (!bibbiData) return;
  const isbn = bibbiData?.publications?.[0]?.isbn;
  const swrKey = getSwrKey(bibbiData?.workId, isbn);

  if (!swrKey) return;

  setCache(swrKey, bibbiData);
};

const setCache = (endpoint: string, data: BibbiData) => mutate(endpoint, data);
