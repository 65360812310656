import { Box, ChakraProps, colors, Container, Link } from "@biblioteksentralen/react";
import { jsonFetcher } from "@forrigebok/common";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "src/utils/useTranslation";
import useSWR from "swr";
import { VerkSomTrengerFlereRegistreringerResponse } from "../../pages/api/internal/forside/verk-som-trenger-flere-registreringer.api";
import { analyticsVerkSomTrengerFlereRegistreringer } from "../../utils/analytics/analyticsVerkSomTrengerFlereRegistreringer";
import { logClick } from "../../utils/analytics/Plausible";
import { SWRStatus } from "../SWRStatus";
import { VerkListe } from "./VerkListe";

export function VerkSomTrengerFlereRegistreringer({ ...chakraProps }: ChakraProps) {
  const [max, setMax] = useState<undefined | number>(undefined);
  const { t } = useTranslation();

  const [index, dispatch] = useReducer(
    (state: number, action: { type: "increase" } | { type: "set"; index: number }) => {
      switch (action.type) {
        case "increase":
          const nextIndex = state + 5;
          if (max && nextIndex > max) return 0;
          return nextIndex;
        case "set":
          return action.index;
      }
    },
    0
  );

  const verkMedFåRegisteringerResponse = useSWR<VerkSomTrengerFlereRegistreringerResponse>(
    `/api/internal/forside/verk-som-trenger-flere-registreringer?start=${index}`,
    jsonFetcher
  );

  useEffect(() => {
    const maxIndex = verkMedFåRegisteringerResponse.data?.totalt;
    if (!max && maxIndex) {
      setMax(maxIndex);
      dispatch({ type: "set", index: Math.floor(Math.random() * maxIndex) });
    }
  }, [verkMedFåRegisteringerResponse, max]);

  const handleVisAndre = () => {
    dispatch({ type: "increase" });
    logClick("Bidra: Vis andre");
  };

  return (
    <Box backgroundColor={colors.statusYellowLight} paddingY="2rem" minH="25rem" {...chakraProps}>
      <Container maxW="container.lg" position="relative" display="flex" flexDirection="column">
        <Link
          fontWeight={600}
          fontSize="lg"
          as="button"
          onClick={handleVisAndre}
          position={{ md: "absolute" }}
          right=".75rem"
          top=".25rem"
        >
          {t("Vis andre")}
        </Link>
        {!verkMedFåRegisteringerResponse.data ? (
          <SWRStatus centerSpinner swrResponse={verkMedFåRegisteringerResponse} />
        ) : (
          <VerkListe
            tittel={t("Verk som trenger flere registreringer")}
            verk={verkMedFåRegisteringerResponse.data.verk}
            onClick={(verk) => analyticsVerkSomTrengerFlereRegistreringer.forsideKlikk(verk)}
          />
        )}
      </Container>
    </Box>
  );
}
