import { getVerkUrl } from "@forrigebok/common";
import { BibbiData } from "@forrigebok/generatedtypes";
import NextLink from "next/link";
import { forwardRef } from "react";
import { useMount } from "react-use";
import { setBibbiRecordSWRCache } from "../../bibbi-integrasjoner/useBibbiWork";

type Props = {
  bibbiData: BibbiData;
} & Omit<React.ComponentProps<typeof NextLink>, "href">;

// eslint-disable-next-line react/display-name
export const VerkNextLink = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const { bibbiData, children, ...nextLinkProps } = props;
  useMount(() => setBibbiRecordSWRCache(props.bibbiData));

  return (
    <NextLink ref={ref} href={getVerkUrl(props.bibbiData)} {...nextLinkProps}>
      {props.children}
    </NextLink>
  );
});
