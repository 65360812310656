import {
  Box,
  Center,
  ChakraProps,
  colors,
  Container,
  Heading,
  Icon,
  Show,
  Stack,
  VStack,
} from "@biblioteksentralen/react";
import { jsonFetcher } from "@forrigebok/common";
import { useTranslation } from "src/utils/useTranslation";
import useSWR from "swr";
import { analyticsVerkSomTrengerFlereRegistreringer } from "../../../utils/analytics/analyticsVerkSomTrengerFlereRegistreringer";
import { SWRStatus } from "../../SWRStatus";
import { VerkListe } from "../VerkListe";
import { SVGProps } from "react";
import { Teller } from "src/components/CountDown";
import { BannerResponse } from "src/pages/api/internal/banner.api";

const krimSjangere = [
  "Krim",
  "Thrillere",
  "Grøsser",
  "Grøssere",
  "Psykologiske thrillere",
  "Spenning",
  "Spionfortellinger",
];

export function KrimBanner({ ...chakraProps }: ChakraProps) {
  const { t } = useTranslation();
  const krimStatsResponse = useSWR<BannerResponse>(
    `/api/internal/banner?genres=${krimSjangere.join(",")}`,
    jsonFetcher
  );

  return (
    <Box backgroundColor={colors.statusYellowLight} paddingY="2rem" minH="25rem" marginY="2rem" {...chakraProps}>
      <Container maxW="container.lg" position="relative" display="flex" flexDirection="column">
        <Stack spacing="2rem">
          <Center>
            <Heading textAlign="center" position="relative" maxW="15em">
              <Show above="md">
                <Icon
                  as={Egg1}
                  height="auto"
                  width={{ base: "3rem", md: "4rem" }}
                  position="absolute"
                  left={0}
                  transform="translate(-130%, 5%)"
                />
                <Icon
                  as={Egg2}
                  height="auto"
                  width={{ base: "3rem", md: "4rem" }}
                  position="absolute"
                  right={0}
                  transform="translate(130%, 5%)"
                />
              </Show>
              {t("Bli med på å registrere årets påskekrim!")}
            </Heading>
          </Center>
          {!krimStatsResponse.data ? (
            <SWRStatus centerSpinner swrResponse={krimStatsResponse} />
          ) : (
            <>
              <VStack marginBottom="3rem">
                <Box>{t("Så langt har vi")}</Box>
                <Teller count={krimStatsResponse.data.totalt} skiveFarge="white" fontSize="1.75rem" />
                <Box>{t("registrerte kriminalromaner")}</Box>
              </VStack>
              <VerkListe
                tittel={t("Siste krimbøker")}
                verk={krimStatsResponse.data.verk}
                onClick={(verk) => analyticsVerkSomTrengerFlereRegistreringer.forsideKlikk(verk)}
              />
            </>
          )}
        </Stack>
      </Container>
    </Box>
  );
}

const Egg1 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 240 297" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M222.325 110.621c14.213 38.339 15.274 77.224 5.724 109.023-9.55 31.796-29.577 56.215-57.435 66.542-27.857 10.328-58.962 4.865-86.93-13.022-27.972-17.889-52.514-48.068-66.728-86.408-14.213-38.34-15.274-77.225-5.724-109.024 9.55-31.795 29.577-56.214 57.435-66.542 27.857-10.327 58.962-4.864 86.93 13.023 27.971 17.888 52.514 48.068 66.728 86.408Z"
      fill="#E2FCE8"
    />
    <path
      d="M68.667 11.19C40.809 21.518 20.782 45.937 11.232 77.732c-7.536 25.094-8.465 54.599-1.537 84.768L39.5 110.621 107 125.5 147.5 69h53.393c-12.851-18.785-28.452-34.015-45.296-44.787C127.629 6.326 96.524.863 68.667 11.19ZM170.614 286.186c27.858-10.327 47.885-34.746 57.435-66.542 6.523-21.719 8.095-46.745 3.908-72.644H176.5l-36 56L77 186.756 49.54 243c10.342 12.174 21.915 22.343 34.144 30.164 27.968 17.887 59.073 23.35 86.93 13.022Z"
      fill="#E7D5D3"
    />
    <path
      d="M9.695 162.5a196.012 196.012 0 0 0 7.261 24.256c8 21.579 19.271 40.572 32.585 56.244M9.695 162.5c-6.928-30.169-6-59.674 1.537-84.768 9.55-31.795 29.577-56.214 57.435-66.542 27.857-10.327 58.962-4.864 86.93 13.023C172.441 34.985 188.042 50.215 200.893 69M9.695 162.5 39.5 110.621 107 125.5 147.5 69h53.393M49.541 243c10.34 12.174 21.914 22.343 34.143 30.164 27.968 17.887 59.073 23.35 86.93 13.022 27.858-10.327 47.885-34.746 57.435-66.542 6.523-21.719 8.095-46.745 3.908-72.644M49.541 243 77 186.756 140.5 203l36-56h55.457m0 0c-1.943-12.019-5.127-24.226-9.632-36.379C216.67 95.37 209.382 81.409 200.893 69"
      stroke="#0A555D"
      strokeWidth={10}
    />
  </svg>
);

const Egg2 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 235 300" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M223.183 178.815c-10.583 39.496-32.21 71.83-58.395 92.244-26.181 20.413-56.643 28.747-85.341 21.058-28.698-7.69-50.911-30.139-63.38-60.907C3.6 200.438 1.037 161.623 11.62 122.127c10.583-39.497 32.21-71.83 58.394-92.244C96.195 9.47 126.657 1.135 155.356 8.825c28.698 7.689 50.911 30.139 63.379 60.907 12.469 30.772 15.032 69.587 4.449 109.083Z"
      fill="#DFD3E7"
      stroke="#0A555D"
      strokeWidth={10}
    />
    <path
      d="M194.079 240.5c12.68-17.386 22.831-38.278 29.104-61.685 3.005-11.216 4.95-22.377 5.909-33.315L161 126.5l33.079 114Z"
      fill="#E2FCE8"
    />
    <path
      d="M14.619 128.127c-5.681 21.2-7.574 42.204-6.18 61.873L57 202.5 26.307 96c-4.703 10.044-8.65 20.79-11.688 32.127Z"
      fill="#E7D5D3"
    />
    <path d="M65 200.5 36 95l50 12 30.5 107.5-51.5-14Z" fill="#E2FCE8" />
    <path d="m127 219.5-29.5-107L152 126l30.5 106.5-55.5-13Z" fill="#E7D5D3" />
    <path
      d="m25.316 87.815 30.473 7.667 72.649 19.744 41.351 10.587 59.528 16.037M5.99 190.241l28.125 7.035 67.025 18.228 38.162 9.728 54.922 14.796M29.317 89.089l5.221 16.883 11.142 40.834 6.922 22.983 9.256 33.402"
      stroke="#0A555D"
      strokeWidth={12}
    />
    <path
      d="m88.317 101.089 5.396 17.511 11.558 42.337 7.159 23.835 9.596 34.634M154.317 120.089l5.396 17.511 11.558 42.337 7.159 23.835 9.596 34.634"
      stroke="#0A555D"
      strokeWidth={12}
    />
    <path
      d="M223.183 178.815c-10.583 39.496-32.21 71.83-58.395 92.244-26.181 20.413-56.643 28.747-85.341 21.058-28.698-7.69-50.911-30.139-63.38-60.907C3.6 200.438 1.037 161.623 11.62 122.127c10.583-39.497 32.21-71.83 58.394-92.244C96.195 9.47 126.657 1.135 155.356 8.825c28.698 7.689 50.911 30.139 63.379 60.907 12.469 30.772 15.032 69.587 4.449 109.083Z"
      stroke="#0A555D"
      strokeWidth={10}
    />
  </svg>
);
