import {
  Box,
  ChakraProps,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  StackProps,
  Text,
  VisuallyHidden,
  VStack,
} from "@biblioteksentralen/react";
import { ReactNode } from "react";
import { useTranslation } from "src/utils/useTranslation";
import { VerkStats } from "../../pages/api/internal/verk/index.api";

type Props = {
  verkStats: VerkStats;
  label: ReactNode;
  hideLabel?: boolean;
} & ChakraProps;

function BatteriIndikator({ verkStats, label, hideLabel, ...chakraProps }: Props) {
  const { t } = useTranslation();
  return (
    <Popover isLazy>
      <PopoverTrigger>
        <VStack as="button" _hover={{ textDecoration: "underline" }} transition=".2s" spacing=".1em" {...chakraProps}>
          <Indikator stats={verkStats} />
          {hideLabel ? (
            <VisuallyHidden>{label}</VisuallyHidden>
          ) : (
            <Box opacity={0.7} fontSize="sm">
              {label}
            </Box>
          )}
        </VStack>
      </PopoverTrigger>
      <PopoverContent filter="drop-shadow(0 1rem 2rem rgba(0,0,0,.75))">
        <PopoverArrow />
        <PopoverCloseButton alignSelf="flex-end" />
        <PopoverBody padding="1rem">
          <Stack spacing="1rem">
            <Text>{t("Minst 4 registeringer gir oss et godt datagrunnlag.")}</Text>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function Indikator({ stats, ...chakraProps }: { stats?: VerkStats } & StackProps) {
  const antallRegistreringer = stats?.antallRegistreringer ?? 0;
  return (
    <HStack alignItems="center" spacing=".15em" color="green.600" {...chakraProps}>
      {[...new Array(4)].map((_, i) => (
        <Box
          key={i}
          width=".65em"
          height="1.15em"
          borderRadius="sm"
          boxShadow="sm"
          backgroundColor={
            i === stats?.brukersRegistrering?.index
              ? "blue.300"
              : i < antallRegistreringer
              ? "green.600"
              : "blackAlpha.300"
          }
        />
      ))}
    </HStack>
  );
}

export default BatteriIndikator;
