import { Verk } from "@forrigebok/generatedtypes";
import { localStorageStringArray } from "./localStorageStringArray";
import { logClick } from "./Plausible";

const localStorageKey = "analyticsSisteRegistreringer";

const { getValues, addValue, removeValue } = localStorageStringArray(localStorageKey);

export const analyticsSisteRegistreringer = {
  forsideKlikk: (verk: Pick<Verk, "_id">) => {
    logClick("Siste registreringer: Klikk forside");
    addValue(verk._id);
  },
  lagRegistrering: (verk?: Pick<Verk, "_id">) => {
    if (verk?._id && getValues().includes(verk._id)) {
      logClick("Siste registreringer: Start registrering");
    }
  },
  publiserRegistrering: (verk?: Pick<Verk, "_id">) => {
    if (verk && getValues().includes(verk._id)) {
      logClick("Siste registreringer: Publiser registrering");
      removeValue(verk._id);
    }
  },
};
