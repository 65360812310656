import { colors } from "@biblioteksentralen/react";
import { Box, ChakraProps, Image, Skeleton } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { BibbiData } from "@forrigebok/generatedtypes";
import { isKulturfond } from "common/bibbi-integrasjoner/tags";
import BokIkon from "../../ikoner/Bok";
import { getAccentColorFromSeed } from "../../utils/colors";

interface Props extends ChakraProps {
  bibbiData: Pick<BibbiData, "coverImage" | "title" | "tags">;
}

function WorkImage({ bibbiData, ...chakraProps }: Props) {
  const imgUrl = bibbiData.coverImage;

  return (
    <Box position="relative" overflow="hidden" borderRadius="lg" width="100%" {...chakraProps}>
      {isKulturfond(bibbiData) && (
        <Box
          position="absolute"
          background={colors.neptune["500"]}
          color="white"
          fontSize=".75em"
          fontWeight="600"
          padding=".15em"
          width="10em"
          textAlign="center"
          right="-3.15em"
          top=".85em"
          transform="rotate(45deg)"
          // eslint-disable-next-line i18next/no-literal-string
        >
          K-fond
        </Box>
      )}
      <CoverImage imageUrl={imgUrl} title={bibbiData.title ?? "N/A"} />
    </Box>
  );
}

interface CoverImageProps extends ChakraProps {
  imageUrl?: string;
  title: string;
}

export const CoverImage = ({ imageUrl, title, ...chakraProps }: CoverImageProps) => {
  if (!imageUrl) {
    return (
      <Box
        css={`
          aspect-ratio: 0.7;
        `}
        backgroundColor={getAccentColorFromSeed(`${title}`)}
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        fontSize="3rem"
        width="100%"
        {...chakraProps}
      >
        <BokIkon aria-hidden />
      </Box>
    );
  }

  return (
    <Image
      fallback={
        <Skeleton
          css={css`
            aspect-ratio: 0.7;
          `}
          speed={1.5}
          borderRadius="lg"
          {...chakraProps}
        />
      }
      borderRadius="lg"
      src={imageUrl}
      alt=""
      loading="lazy"
      {...chakraProps}
    />
  );
};

export default WorkImage;
