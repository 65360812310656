import { jsonFetcher } from "common";
import { SeasonalBannersConfig } from "src/pages/api/internal/seasonalbanners-config.api";
import useSWR from "swr";
import { SWRStatus } from "../SWRStatus";
import { KrimBanner } from "./krimBanner/KrimBanner";
import { NyhetsBanner } from "./NyhetsBanner";
import { SommerlesBanner } from "./Sommerles/SommerlesBanner";

export const useBannerConfig = () => useSWR<SeasonalBannersConfig>("/api/internal/seasonalbanners-config", jsonFetcher);

export const Banner = () => {
  const seasonalBannersConfigResponse = useBannerConfig();

  if (seasonalBannersConfigResponse.error) return <SWRStatus swrResponse={seasonalBannersConfigResponse} />;
  if (!seasonalBannersConfigResponse.data) return null;

  const { banner } = seasonalBannersConfigResponse.data;

  switch (banner) {
    case "sommerles":
      return <SommerlesBanner />;
    case "krim":
      return <KrimBanner />;
    case "news":
      return <NyhetsBanner news={seasonalBannersConfigResponse.data.news} />;
    default:
      return null;
  }
};
