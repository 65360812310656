import * as React from "react";

function BokIkon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M143.5 27.1c-3 .2-5.9 0-9 0v-9.6c0-4.3-1.6-6-5.8-6.4a75.1 75.1 0 00-29.8 3A48.5 48.5 0 0076 28.8l-1 1-3.5-3.7a52.6 52.6 0 00-27.4-13.8 74.5 74.5 0 00-23.9-1 5 5 0 00-4.6 5.2V27H5a5 5 0 00-5 5v94.2c0 3 1.4 5.1 4 5.6a7.8 7.8 0 003.3-.4 126 126 0 0136.5-5.5c9.9 0 19.5 1.5 28.7 5.5a6 6 0 005 0 75.6 75.6 0 0134-5.4 130.3 130.3 0 0131.5 5.6c3.3 1 5.1 0 7-3.5V31c-1.2-3-3.4-4-6.5-3.9zM10.1 120.3v-83h5.4v66a5.7 5.7 0 001.9 4.7 5.6 5.6 0 004 1.2c4.7 0 9.5-.3 14.3 0a48 48 0 0122.3 7l1.2.8c-16.8-2.4-33-.8-49 3.3zm59.8-7.8c-12.8-11-28-14-44.2-13.8V20.8h2.6a61 61 0 0124.4 4.6 36.3 36.3 0 0116.8 14.3 3.3 3.3 0 01.5 1.6v70.5a3.9 3.9 0 010 .7zm10.1-1.3V41.5c0-2 1.5-3.4 2.6-4.8A38.8 38.8 0 01103 23.4a65.3 65.3 0 0120.1-2.5l1.2.1v77.7C108 98.5 93 101.5 80 112.5l-.1-1.3zm59.8 9c-16.1-4-32.3-5.6-49-3.1a36 36 0 019.8-5 64.6 64.6 0 0126.7-3l2 .1a5 5 0 005-4.6 17.2 17.2 0 000-1.7V37.3h5.5z"
        fill="currentColor"
      />
    </svg>
  );
}

export default BokIkon;
