import { Button, colors, Container, Heading, HStack, Stack, Text } from "@biblioteksentralen/react";
import { fremtredendeFarge } from "../../utils/colors";
import SearchInput from "../layout/header/SearchInput";
import NextLink from "next/link";
import { useTranslation } from "src/utils/useTranslation";

function KomIgang() {
  const { t } = useTranslation();

  return (
    <Container maxW="container.lg" paddingX={{ md: ".75rem" }}>
      <Stack
        backgroundColor={fremtredendeFarge}
        color="white"
        padding={{ base: "1rem .75rem 1.5rem", md: "1.5rem 1.25rem" }}
        borderRadius={{ md: "md" }}
      >
        <Heading as="h2" fontSize={{ base: "lg", md: "1.7rem" }}>
          {t("Lag en ny registrering")}
        </Heading>
        <SearchInput />
        <HStack marginTop="1rem !important">
          <Text fontWeight={600} fontSize="lg">
            eller
          </Text>
          <Button
            as={NextLink}
            href="/vokabular"
            _hover={{ cursor: "pointer", backgroundColor: colors.neptune[400] }}
            backgroundColor={colors.neptune[300]}
            color={colors.neptune["900"]}
          >
            {t("Utforsk vokabularet")}
          </Button>
        </HStack>
      </Stack>
    </Container>
  );
}

export default KomIgang;
