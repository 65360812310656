import { BibbiData } from "@forrigebok/generatedtypes";
import { BoxProps, Heading, HeadingProps, Stack, Text } from "@chakra-ui/react";
import { css } from "@emotion/react";

type Props = {
  bibbiData: Pick<BibbiData, "title" | "subtitle">;
  hideSubtitle?: boolean;
  headingProps?: HeadingProps;
} & BoxProps;

export function WorkTitle({ bibbiData, headingProps, hideSubtitle, ...chakraProps }: Props) {
  return (
    <Stack {...chakraProps} spacing=".1em">
      <Heading fontWeight={700} size="md" as="h3" noOfLines={2} {...headingProps}>
        {bibbiData?.title}
      </Heading>
      {!hideSubtitle && bibbiData.subtitle && (
        <Text
          noOfLines={1}
          fontWeight={600}
          css={css`
            &::first-letter {
              text-transform: capitalize;
            }
          `}
        >
          {bibbiData.subtitle}
        </Text>
      )}
    </Stack>
  );
}
