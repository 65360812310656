export const localStorageStringArray = (key: string) => {
  const getValues = (): string[] => {
    const value = localStorage.getItem(key);
    if (!value) return [];
    try {
      const parsedValue = JSON.parse(value);
      return parsedValue;
    } catch (e) {
      return [];
    }
  };

  const addValue = (id: string) => {
    const currentValue = getValues();
    localStorage.setItem(key, JSON.stringify([...currentValue, id]));
  };

  const removeValue = (id: string) => {
    const currentValue = getValues();
    localStorage.setItem(key, JSON.stringify(currentValue.filter((it) => it !== id)));
  };

  return {
    getValues,
    addValue,
    removeValue,
  };
};
