import { Box, LinkBox, LinkBoxProps, LinkOverlay, Skeleton, useId, withErrorBoundary } from "@biblioteksentralen/react";
import { css } from "@emotion/react";
import { VerkMedStats } from "../../pages/api/internal/verk/index.api";
import BatteriIndikator from "../verk/BatteriIndikator";
import { VerkNextLink } from "../verk/VerkNextLink";
import WorkImage from "./CoverImage";
import { AuthorsLine } from "./Metadata";
import { WorkTitle } from "./WorkTitle";

function VerkPreview({ verk, ...chakraProps }: { verk: VerkMedStats } & LinkBoxProps) {
  const headingId = useId();
  const { bibbiData } = verk;
  if (!bibbiData)
    return (
      <Skeleton
        css={css`
          aspect-ratio: 0.6;
        `}
        borderRadius=".75rem"
        {...chakraProps}
      />
    );

  return (
    <LinkBox
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      color="inherit"
      textDecoration="none"
      maxWidth="20rem"
      transition=".5s"
      _hover={{ transform: "scale(1.02)", transition: ".2s" }}
      aria-labelledby={headingId}
      {...chakraProps}
    >
      <Box position="relative">
        <WorkImage bibbiData={bibbiData} boxShadow="md" />
        {verk.stats && (
          <Box
            position="absolute"
            bottom={0}
            right={0}
            backgroundColor="rgba(243, 243, 243, 0.89)"
            padding=".25rem .35rem .2em"
            borderBottomRightRadius="lg"
            borderTopLeftRadius="md"
            overflow="hidden"
          >
            <BatteriIndikator
              fontSize="sm"
              verkStats={verk.stats}
              label={`${verk.stats.antallRegistreringer} registreringer`}
              hideLabel
            />
          </Box>
        )}
      </Box>
      <Box minH="4.3rem" padding=".15rem">
        <VerkNextLink bibbiData={bibbiData} legacyBehavior passHref>
          <LinkOverlay>
            <WorkTitle
              bibbiData={bibbiData}
              hideSubtitle
              headingProps={{
                fontSize: "md",
                padding: "0.25rem 0",
                as: "h3",
                size: "md",
              }}
              id={headingId}
            />
          </LinkOverlay>
        </VerkNextLink>
        <AuthorsLine marginTop="-.1rem" fontSize="sm" hideYearPublished bibbiData={bibbiData} />
      </Box>
    </LinkBox>
  );
}

export default withErrorBoundary(VerkPreview, "BokPreview");
