import { ChakraProps, Flex, FlexProps, Link, Stack } from "@biblioteksentralen/react";
import styled from "@emotion/styled";
import { uniqueStrings } from "@forrigebok/common";
import { BibbiData } from "@forrigebok/generatedtypes";
import NextLink from "next/link";
import { useState } from "react";
import { useTranslation } from "src/utils/useTranslation";

export const LinjeMedDotter = styled(Flex)`
  flex-wrap: wrap;
  line-height: 1.3;
  > *:not(:last-child):after {
    display: inline-block;
    content: "•";
    padding: 0 0.5em;
  }
`;

interface Props extends ChakraProps {
  bibbiData: BibbiData;
}

function Metadata({ bibbiData, ...chakraProps }: Props) {
  return (
    <Stack alignItems="flex-start" {...chakraProps}>
      <AuthorsLine bibbiData={bibbiData} />
      <Tags bibbiData={bibbiData} />
    </Stack>
  );
}

const defaultTagLimit = 4;

export function AuthorsLine({
  bibbiData,
  hideYearPublished,
  ...chakraProps
}: { bibbiData: BibbiData; hideYearPublished?: boolean } & FlexProps) {
  const authors = uniqueStrings(bibbiData?.authors ?? []);
  const originalYear = bibbiData?.originalYear;

  return (
    <LinjeMedDotter {...chakraProps}>
      {authors.map((author) => (
        <Link as={NextLink} key={author} href={`/forfatter/${author}`} variant="plain">
          {author}
        </Link>
      ))}
      {!hideYearPublished && originalYear && <span>{originalYear}</span>}
    </LinjeMedDotter>
  );
}

function Tags({ bibbiData }: Props) {
  const tags = [...(bibbiData.genre ?? []), ...(bibbiData.about ?? [])];
  const uniqueTags = Array.from(new Set(tags));
  const [visAlle, setVisAlle] = useState(tags.length <= defaultTagLimit);
  const { t } = useTranslation();

  const tagsToDisplay = visAlle ? uniqueTags : uniqueTags.slice(0, defaultTagLimit - 1);

  return (
    <LinjeMedDotter fontSize="sm" fontWeight={600}>
      {tagsToDisplay.map((tag) => (
        <span key={tag}>{tag}</span>
      ))}
      {!visAlle && (
        <Link as="button" onClick={() => setVisAlle(true)}>
          {t("Vis mer")}
        </Link>
      )}
    </LinjeMedDotter>
  );
}

export default Metadata;
